.leo {
    width: 80%;
    .arrow {
        top: 5%;
        left: 5%;
        position: absolute;
        width: 45px;
        height: 45px;
        color: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #fff;
        cursor: pointer;
        // display: inherit;
    }
    .lay1 {
        height: 550px;
    }
    .layer {
        height: 100%;
    }
    .layvh {
        height: 70vh;
    }
    .lay2 {
        height: 80vh;
    }
    img {
        width: 100%;
        height: 100%;
    }
}
