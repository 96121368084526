@import url('https://fonts.googleapis.com/css2?family=El+Messiri&family=Jacques+Francois&family=Lovers+Quarrel&family=Luckiest+Guy&family=Ma+Shan+Zheng&family=Miss+Fajardose&display=swap');
.home {
    background: #3E2E1B;
    position: relative;
    overflow: hidden;
    h4 {
        font-size: 25px;
        color: #F9CD8C;
        font-family: 'Lovers Quarrel', cursive;

    }
    canvas {
        width: 100%;
        height: 100%;
    }

}
.mouseCursor {
    position: absolute;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    transform: translate(-50%,-50%);
    background: #D09B6B;
    mix-blend-mode: difference;
    left: var(--x-m);
    top: var(--y-m);
    z-index: 1000000000000000;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mouseInside {
    position: absolute;
    width: 10px;
    height: 10px;
    z-index: 1000000000000000;
    mix-blend-mode: hard-light;
    transform: translate(-50%,-50%);
    border-radius: 50%;
    left: var(--x-m-i);
    top: var(--y-m-i);
    background: rgba(255, 255, 255, 0.61);
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mouseInsideEnlarge {
    width: 80px;
    height: 80px;  
    background: #3E2E1B;  
}

.banner {
    height: 100vh;
}

@media (max-width:1920px) {
    .banner-img {
        height: 100%;
    }
}

@media (max-width:900px) {
    .banner-img {
        height: 60%;
    }
}




@media (max-width:700px) {
    
}

.Images {
    --line-width:10%;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // background: rgba(255, 255, 255, 0.226);
    // border-radius: 10px;
    cursor: pointer;
    .text {
        // transform: translate(-50%);
        font-size: 3vw;
        padding: 5%;
        bottom: 25%;
        // background: orange;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Miss Fajardose', cursive;
    }
    .lines {
        width: var(--line-width);
        height: 3px;
        background: #F9CD8C;
        position: absolute;
        // left: 0;
        bottom: 0;
        // top: 0;
        // bottom:0;
    }
    .dashing {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 10000000;
        background: #7e5732;
    }
    .another {
        // background: orange;
    }
    .hide {
        display: inline-block;
        transform: translateY(0%);
        // background: orange;
    }
}
.banner-img:nth-child(2) .Images {
    .lines {
        top: 0;
    }
}
